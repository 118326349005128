$base-url-fonts: '../../assets/fonts';

@font-face {
  font-family: 'Poppins';
  src: url('#{$base-url-fonts}/Poppins.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('#{$base-url-fonts}/Poppins-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('#{$base-url-fonts}/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('#{$base-url-fonts}/Poppins-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
