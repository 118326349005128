/* You can add global styles to this file, and also import other style files */
@import './styles/main';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
}

.text--wrap {
  word-break: break-all;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}
