@import '@cds.js/core/dist/cargamos/cargamos.css';
@import './base/breakpoints';
@import 'base/breakpoints';
@import 'base/typography';
@import 'plugins/plugins';

html {
  background: #f7f7f8;
  overflow-x: hidden;
}

.ethos {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vh 2rem;

  &__content {
    max-width: 1100px;
  }

  &__title {
    font-size: 55px;
    font-weight: 800;
  }

  &__list {
    margin-top: 1rem;
    padding-left: 3rem;
    list-style: none;
  }

  &__item {
    font-size: 24px;
    margin-top: 2rem;

    &--strong {
      font-weight: 800;
    }

    &--description {
      font-weight: 100;
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 36px;
    }

    &__list {
      padding-left: 2rem;
    }

    &__item {
      font-size: 16px;
    }
  }
}

.benefits__container {
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 0.5rem;

  &__item {
    flex: calc(25% - 2rem);
  }
}

@include desktop() {
  .benefits__container {
    &__item {
      flex: calc(25% - 2rem);
    }
  }
}

@include tablet() {
  .benefits__container {
    &__item {
      flex: calc(50% - 2rem);
    }
  }
}

@include tablet-and-mobile() {
  .benefits__container {
    padding-left: 8%;
    &__item {
      flex: calc(100% - 2rem);
    }
  }
}

@include mobile() {
  .benefits__container {
    align-items: center;
    justify-content: center;
    padding-left: 9%;
    &__item {
      flex: calc(100% - 2rem);
    }
  }
}

@include mobile-sm() {
  .benefits__container {
    padding-left: 0;
    &__item {
      flex: calc(100% - 2rem);
    }
  }
}
